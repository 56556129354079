<template>
  <v-dialog v-model="dialog" persistent :max-width="dialogWidth" class="scroll">
    <v-card class="px-5 pb-8 scroll">
      <v-container fluid v-if="page === 1">
        <!-- <pre>{{ selectedTests }}</pre> -->
        <v-row class="d-flex align-center py-4">
          <div>
            <v-radio-group v-model="filter.type" row @change="applyFilter()">
              <v-radio :label="$t('technician')" value="technician"></v-radio>
              <v-radio
                :label="$t('psychological')"
                value="psychological"
              ></v-radio>
            </v-radio-group>
          </div>
          <div>
            <v-checkbox
              v-model="filter.user"
              :label="$t('mytest')"
              :value="currentUser.id"
              @change="applyFilter()"
            ></v-checkbox>
          </div>
          <v-spacer></v-spacer>
          <div class="px-4">
            <test-filter @apply="applyTestFilter" @clean="applyTestFilter" />
          </div>
          <div>
            <v-text-field
              v-model="textSearch"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </div>
        </v-row>
        <v-divider></v-divider>

        <infinite-scroll
          :items="filteredItems"
          :totalPages="totalPages"
          @refetch="fetch"
        >
          <template v-slot:item="{ item }">
            <v-skeleton-loader
              v-if="$apollo.loading"
              elevation="2"
              v-bind="filteredItems"
              type="image, article"
            ></v-skeleton-loader>
            <test-card
              v-else
              :selectedTests="selectedTests"
              :select="true"
              :test="item"
              @preview="showPreviewDialog"
              @select="selectTest"
            />
          </template>
        </infinite-scroll>
      </v-container>
      <v-row class="d-flex justify-end align-center py-0 px-2">
        <v-btn text @click="closeDialog()" color="primary">{{
          $t("cancel")
        }}</v-btn>
        <v-btn color="primary" @click="sendTest()"
          ><v-icon left v-show="false">mdi-send</v-icon>
          {{ $t("associate") }}</v-btn
        >
      </v-row>
    </v-card>

    <preview-test-dialog
      :dialog="previewDialog"
      :test="test"
      @close="previewDialog = false"
    />

    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-dialog>
</template>

<script>
import { GET_TESTS_ENTITY_QUERY } from "../../test/graphql/Query.service";
import { GET_ALL_VACANCIES_QUERY } from "../graphql/Query.resolver";
import { mapActions, mapGetters } from "vuex";
import InfiniteScroll from "./InfiniteScroll.vue";
import moment from "moment";
import PreviewTestDialog from "./../../test/components/PreviewTestDialog.vue";
import TestFilter from "../../../components/TestFilter.vue";
import TestCard from "../../test/components/TestCard.vue";
import ErrorDialog from "../../../components/ErrorDialog.vue";
import ProgressDialog from "../../../components/ProgressDialog.vue";
import SuccessDialog from "../../../components/SuccessDialog.vue";
import { ADD_DIALOG } from "@/mixins/dialog";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import Test from "@/models/Test";

export default {
  name: "SelectAssociateTest",
  components: {
    PreviewTestDialog,
    ErrorDialog,
    InfiniteScroll,
    ProgressDialog,
    SuccessDialog,
    TestCard,
    TestFilter,
  },
  mixins: [ADD_DIALOG, replaceSpecialCharsMixins],
  props: {
    dialog: Boolean,
    SelectTestIndex: Number,
  },
  data: () => ({
    select: [],
    vacancies: [],
    appliedFilter: true,
    date: null,
    filter: {
      areas: undefined,
      category: "",
      entity: undefined,
      limit: 4,
      page: 1,
      positions: undefined,
      type: "",
    },
    menuDate: false,
    menuTime: false,
    page: 1,
    previewDialog: false,
    selectedTests: [],
    test: new Test(),
    tests: [],
    textSearch: undefined,
    showPayment: false,
    time: null,
    totalPages: 1,
  }),

  apollo: {
    tests: {
      query: GET_TESTS_ENTITY_QUERY,
      variables() {
        return { filterTest: this.filter };
      },
      fetchPolicy: "no-cache",
    },
    vacancies: {
      query: GET_ALL_VACANCIES_QUERY,
      variables() {
        return { filterTest: this.filter };
      },
      fetchPolicy: "no-cache",
    },
  },

  watch: {
    tests: function(val) {
      if (this.appliedFilter) {
        this.setTestsState(val.tests);
        this.appliedFilter = false;
      } else {
        this.setTestsScrolled(val.tests);
      }
      this.totalPages = val.totalPages;
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getTests: "test/getTests",
    }),

    dialogWidth() {
      return this.page === 1 ? "70%" : "30%";
    },
    filteredItems() {
      let resultItems = [...this.getTests];
      resultItems = resultItems.filter(
        (test) =>
          !(
            test.entity &&
            test.creatorType === "partner" &&
            !test.status &&
            test.reason != null
          ) &&
          test.user != null &&
          !test.isDraft
      );

      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.title).includes(text)
        );
      }
      return resultItems;
    },
  },

  methods: {
    ...mapActions({
      removeTestState: "test/removeTest",
      setTestsState: "test/setTests",
      setTestsScrolled: "test/setTestsScrolled",
    }),
    async applyFilter() {
      this.appliedFilter = true;
      this.fetch(1);
    },
    applyTestFilter(filter) {
      this.filter = { ...filter };
      this.applyFilter();
    },
    changePage(page) {
      this.filter.page = page;
      this.applyFilter();
    },
    cleanFilter() {
      this.filter = {
        areas: undefined,
        category: undefined,
        positions: undefined,
      };
    },
    closeDialog() {
      this.page = 1;
      // this.selectedTests = [];
      this.$emit("close");
    },

    dateFormat() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    async fetch(page) {
      console.log(page);
      if (page > this.totalPages) {
        return;
      }
      this.filter.page = page;
      await this.$apollo.queries.tests.refetch();
    },
    nextStep() {
      this.page = 2;
    },
    selectTest(test) {
      const index = this.selectedTests.findIndex((t) => t.id == test.id);
      if (index > -1) {
        this.selectedTests.splice(index, 1);
      } else {
        this.selectedTests.push(
          Object.assign({}, { ...test, user: test.user })
        );
      }
    },

    async sendTest() {
      this.isLoading = true;
      try {
        if (this.formatNoPriceTest().length > 0) {
          //associar teste aqui
          this.$emit(
            "continue",
            this.formatTestID(),
            this.SelectTestIndex,
            this.selectedTests
          );
        }
        // this.success = this.$t("send_test_associate");
        // this.showSuccess = true;
        this.closeDialog();
      } catch (error) {
        console.log(error);
        this.error = error.message;
        this.showError = true;
      } finally {
        this.isLoading = false;
        this.showPayment = false;
      }
    },
    showPreviewDialog(test) {
      this.test = test;
      this.previewDialog = true;
    },

    formatNoPriceTest() {
      var test = [];
      this.selectedTests.forEach((t) => {
        delete t.user;
        test.push(t);
      });
      return test;
    },

    formatTestID() {
      return this.selectedTests.map((element) => {
        return element.id;
      });
    },

    getTotalPrice() {
      var total = 0;
      var subtotal = 0;
      this.selectedTests.forEach((element) => {
        subtotal = element.price * 1;
        total += subtotal;
      });
      return total;
    },
  },
};
</script>

<style scoped>
.scroll-test {
  height: 450px;
  overflow: auto;
}
</style>
