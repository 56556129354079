<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="350"
  >
    <v-card class="pb-2">
      <v-system-bar
        window
        color="white"
      >
        <v-spacer></v-spacer>
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </v-system-bar>
      <v-card-text class="primary--text text-center justify-center pt-1">
        <span class="py-1 black--text">{{$t('langvacancy')}}</span>

        <v-row class="mt-1">
          <v-col cols="12" md="6">
            <v-btn
              color="primary"
              block
              class="mx-1"
              @click="$emit('set', 'pt')"
            >{{$t('portuguese')}}</v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn
              color="primary"
              block
              outlined
              @click="$emit('set', 'en')"
            >{{$t('english')}}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SelectLanguageModal',
  props: {
    dialog: Boolean
  },
  data: () => ({
  })
}
</script>